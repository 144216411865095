type AvvToastType = 'default' | 'loading' | 'success' | 'warning' | 'error'

type AvvToastParams = {
  message: string
  type?: AvvToastType
  icon?: string
  inverted?: boolean
}

class AvvToast {
  #element: HTMLElement
  #textElement: HTMLElement
  #iconElement: HTMLElement

  #timeout1?: NodeJS.Timeout
  #timeout2?: NodeJS.Timeout

  constructor(element: HTMLElement, params: AvvToastParams) {
    this.#element = element
    this.#textElement = element.querySelector('.avv-toast-text') as HTMLElement
    this.#iconElement = element.querySelector(
      '.avv-toast-icon > i'
    ) as HTMLElement

    this.update(params)

    setTimeout(() => this.#element.classList.add('visible'), 0)
  }

  update(params: AvvToastParams) {
    this.type = params.type ?? 'default'
    this.icon = params.icon
    this.message = params.message
    this.inverted = params.inverted
  }

  remove() {
    this.#stopDecay()

    this.#element.remove()
  }

  set inverted(value: boolean | undefined) {
    if (value) {
      this.#element.classList.add('with-inverted-colors')
    } else {
      this.#element.classList.remove('with-inverted-colors')
    }
  }

  set type(type: AvvToastType) {
    this.#element.setAttribute('data-type', type)

    if (type === 'loading') {
      this.#stopDecay()
    } else {
      this.#startDecay()
    }
  }

  set icon(icon: string | undefined) {
    if (typeof icon === 'string') {
      this.#element.classList.add('with-icon')

      this.#iconElement.innerText = icon
    } else {
      this.#element.classList.remove('with-icon')
    }
  }

  set message(message: string) {
    this.#textElement.innerText = message
  }

  #startDecay() {
    this.#timeout1 = setTimeout(
      () => this.#element.classList.remove('visible'),
      2000
    )
    this.#timeout2 = setTimeout(() => this.#element.remove(), 2500)
  }

  #stopDecay() {
    clearTimeout(this.#timeout1)
    clearTimeout(this.#timeout2)
  }
}

class AvvToastManager {
  #container: HTMLElement

  constructor() {
    // Create container
    this.#container = document.createElement('div')
    this.#container.classList.add('avv-toast-container')

    // Insert container into body
    document.body.insertAdjacentElement('beforeend', this.#container)
  }

  toast(params: AvvToastParams) {
    // Build toast
    const element = document.createElement('div') as HTMLDivElement & {
      $toast: AvvToast
    }
    element.classList.add('avv-toast')
    element.innerHTML =
      '<div class="avv-toast-icon"><i aria-hidden="true" class="material-symbols-outlined"></i></div><div class="avv-toast-text"></div>'

    // Insert into container
    this.#container.insertAdjacentElement('afterbegin', element)

    // Return as toast object
    const toast = new AvvToast(element, params)

    element.$toast = toast

    return toast
  }

  clear() {
    for (const element of this.#container.children) {
      ;(element as HTMLDivElement & { $toast: AvvToast }).$toast.remove()
    }
  }
}

const _avv_toast_manager = new AvvToastManager()

const _avv_toast = (params: AvvToastParams): AvvToast => {
  return window.avv_toast_manager.toast(params)
}

window.avv_toast_manager = _avv_toast_manager
window.avv_toast = _avv_toast

declare global {
  interface Window {
    avv_toast_manager: typeof _avv_toast_manager
    avv_toast: typeof _avv_toast
  }

  const avv_toast_manager: typeof _avv_toast_manager
  const avv_toast: typeof _avv_toast
}

export default {}
